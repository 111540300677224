import React from "react";
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const PartnerilePageTemplate = ({ title, description, pealkiri, alaPealkiri, content, contentComponent }) => {

  const PageContent = contentComponent || Content;

  return (
    <div>
      <Helmet
        title={title}
        meta={[
          { name: 'description', content: description }
        ]}
      >
      </Helmet>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{pealkiri}</h2>
            <p>{alaPealkiri}</p>
          </div>
        </header>
        <div className="wrapper">
          <div className="inner">
            <PageContent className="content" content={content} />
          </div>
        </div>
      </section>
    </div>
  );
};

const PartnerilePage = () => {
  const data = useStaticQuery(graphql`
  query PartnerilePage {
  markdownRemark(frontmatter: {templateKey: {eq: "partnerile-page"}}) {
    frontmatter {
      templateKey
      description
      alaPealkiri
      pealkiri
      title
    }
    html
  }
}
`)

  return (
    <Layout fullMenu>
      <PartnerilePageTemplate
        contentComponent={HTMLContent}
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
        content={data.markdownRemark.html}
        pealkiri={data.markdownRemark.frontmatter.pealkiri}
        alaPealkiri={data.markdownRemark.frontmatter.alaPealkiri}
      />
    </Layout>
  );
};


PartnerilePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  pealkiri: PropTypes.string.isRequired,
  alaPealkiri: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

export default PartnerilePage;